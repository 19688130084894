var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

exports = function (module) {
  try {
    return _nullRequire(module);
  } catch (e) {}
};

export default exports;