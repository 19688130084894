import _optional from "./optional";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var optional = _optional,
    tough = optional("tough-cookie"),
    Cookie = tough && tough.Cookie,
    CookieJar = tough && tough.CookieJar;

exports.parse = function (str) {
  if (str && str.uri) str = str.uri;
  if (typeof str !== "string") throw new Error("The cookie function only accepts STRING as param");

  if (!Cookie) {
    return null;
  }

  return Cookie.parse(str);
}; // Adapt the sometimes-Async api of tough.CookieJar to our requirements


function RequestJar() {
  (this || _global)._jar = new CookieJar();
}

RequestJar.prototype.setCookie = function (cookieOrStr, uri) {
  return (this || _global)._jar.setCookieSync(cookieOrStr, uri);
};

RequestJar.prototype.getCookieString = function (uri) {
  return (this || _global)._jar.getCookieStringSync(uri);
};

exports.jar = function () {
  if (!CookieJar) {
    // tough-cookie not loaded, return a stub object:
    return {
      setCookie: function () {},
      getCookieString: function () {}
    };
  }

  return new RequestJar();
};

export default exports;