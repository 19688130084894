import _util from "util";
import _process from "process";
var exports = {};
var process = _process;
var util = _util;

exports = function debug() {
  if (/\brequest\b/.test(process.env.NODE_DEBUG)) console.error("REQUEST %s", util.format.apply(util, arguments));
};

export default exports;