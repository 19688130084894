var exports = {};

exports = function copy(obj) {
  var o = {};
  Object.keys(obj).forEach(function (i) {
    o[i] = obj[i];
  });
  return o;
};

export default exports;