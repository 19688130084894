var exports = {};

// Safe toJSON
exports = function getSafe(self, uuid) {
  if (typeof self === "object" || typeof self === "function") var safe = {};
  if (Array.isArray(self)) var safe = [];
  var recurse = [];
  Object.defineProperty(self, uuid, {});
  var attrs = Object.keys(self).filter(function (i) {
    if (i === uuid) return false;
    if (typeof self[i] !== "object" && typeof self[i] !== "function" || self[i] === null) return true;
    return !Object.getOwnPropertyDescriptor(self[i], uuid);
  });

  for (var i = 0; i < attrs.length; i++) {
    if (typeof self[attrs[i]] !== "object" && typeof self[attrs[i]] !== "function" || self[attrs[i]] === null) {
      safe[attrs[i]] = self[attrs[i]];
    } else {
      recurse.push(attrs[i]);
      Object.defineProperty(self[attrs[i]], uuid, {});
    }
  }

  for (var i = 0; i < recurse.length; i++) {
    safe[recurse[i]] = getSafe(self[recurse[i]], uuid);
  }

  return safe;
};

export default exports;